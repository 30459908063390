import Button from "@material-ui/core/Button";
import scss from "./DepartmentMenuItem.module.scss";
import {memo, useEffect, useRef, useState} from "react";
import {ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import Link from "Components/Link";

export default memo(props => {

	const {departmentId, item} = props;

	const mainComponentRef = useRef();
	const [mainComponentRefState, setMainComponentRefState] = useState(null);


	/**
	 * Hacky effect to set `mainComponentRefState` to the DOM node reference 
	 * held by `mainComponentRef` when it's first populated (to stop the MUI 
	 * `Menu` component getting an invalid reference as its `anchorEl` due to 
	 * ref population race condition on first render)
	 */
	useEffect(() => {
		setTimeout(() => {
			setMainComponentRefState(mainComponentRef?.current);
		}, 0);
	}, [mainComponentRef]);


	/**
	 * Click away handler.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleClickAway = e => {

		/**
		 * We ignore other events to stop double-eventing with the 
		 * "click" event, as pointer events fire concurrently with 
		 * clicks on touch devices etc.
		 *
		 * Otherwise clicking the menu button to close the menu 
		 * while it's open results in the menu immediately reappearing.
		 */
		if (!window.TouchEvent || !(e instanceof TouchEvent)) {
			props.onMenuClose();
		}

	};


	/**
	 * We have to adjust the URI to include the department ID.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	const getUri = uri => {
		uri += (!uri.includes("?") ? "?" : "&");
		uri += `d=${departmentId}`;
		return uri;
	};


	/**
	 * Pointer entered the menu button.
	 *
	 * @param {PointerEvent} e
	 * @return {void}
	 */
	const handlePointerEnter = e => {

		e.preventDefault();
		e.stopPropagation();

		/**
		 * We ignore other events to stop double-eventing with the 
		 * "click" event, as pointer events fire concurrently with 
		 * clicks on touch devices etc.
		 */
		if (e.pointerType === "mouse") {
			props.onMenuOpen();
		}

	};


	/**
	 * Pointer left the menu button.
	 *
	 * @param {PointerEvent} e
	 * @return {void}
	 */
	const handlePointerLeave = e => {

		e.preventDefault();
		e.stopPropagation();

		/**
		 * We ignore other events to stop double-eventing with the 
		 * "click" event, as pointer events fire concurrently with 
		 * clicks on touch devices etc.
		 */
		if (e.pointerType === "mouse") {
			props.onMenuClose();
		}

	};


	/**
	 * Render the item's menu content
	 */
	const renderMenu = () => (
		<Popper
			anchorEl={mainComponentRefState}
			className={`${scss.root} hideOnPrint`}
			onClose={props.onMenuClose}
			open={props.menuOpen}
			placement="bottom-start">
			<ClickAwayListener
				onClickAway={handleClickAway}>
				<Paper
					square={true}
					variant="outlined">
					<MenuList
						disablePadding={true}
						onPointerLeave={handlePointerLeave}>
						{
							item.Children.map((item, key) => (
								<Link
									key={key}
									uri={getUri(item.Uri)}
									className={scss.menuItemLink}>
									<MenuItem
										children={item.Label}
										dense={true}
										onClick={() => props.onMenuClose()} />
								</Link>
							))
						}
					</MenuList>
				</Paper>
			</ClickAwayListener>
		</Popper>
	);


	/**
	 * Render!
	 */
	return (
		<>
			<Button
				className={scss.button}
				children={item.Label}
				onClick={(!props.menuOpen ? props.onMenuOpen : props.onMenuClose)}
				onPointerEnter={handlePointerEnter}
				ref={mainComponentRef} />
			{(!!item.Children?.length && mainComponentRefState && renderMenu())}
		</>
	);

});
