import React from "react";
import Flex from "Components/Flexx.js";
import Link from "Components/Link.js";
import Navigator from "App/Navigator.js";
import String from "Components/String.js";
import dAccess from "Dispatchers/dAccess.js";
import qs from "query-string";
import scss from "./TabPanel.module.scss";
import withAuth from "Hoc/withAuth.js";
import {Select} from "@heron-web/material";
import {Tab, Tabs} from "@material-ui/core";
import {withRouter, Link as ReactLink} from "react-router-dom";

/**
 * Tab panel component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TabPanel extends React.PureComponent {

	/**
	 * Tab changed.
	 * 
	 * @param {Event} e
	 * @param {String} tabId
	 * @return {void}
	 */
	handleChange = (e, tabId) => {

		if (tabId === this.constructor.extra_link_id) {
			Navigator.navigate(this.props.extraLinkUri);
		}

		const tab = this.props.tabs.find(t => (this.constructor.resolveTabId(t) === tabId));
		if (tab) Navigator.navigate((tab.uri || tab.uris?.[0]));

	};


	/**
	 * Tab changed via `Select`.
	 *
	 * @param {String} tabId
	 * @return {void}
	 */
	handleChangeSelect = tabId => this.handleChange(null, tabId);


	/**
	 * Mounted.
	 *
	 * We dispatch a window resize event to ensure the 
	 * active tab indicator is displayed correctly.
	 *
	 * https://github.com/mui/material-ui/issues/9337
	 *
	 * @return {void}
	 */
	componentDidMount() {

		setTimeout(() => {
			window.dispatchEvent(new CustomEvent("resize"));
		}, 500);

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const tabs = this.constructor.sortTabs(this.tabsVisible);
		if (!tabs.length && this.props.hideWhenNoTabs) return null;

		let tab = this.tab;
		const hide404 = tab?.hide404;
		if (tab?.hidden) tab = null;

		let component = null;
		if (!tab) component = hide404 ? null : this.constructor.render404();
		else if (!tab.permission || this.props.hasPermission(...tab.permission)) component = tab.render?.();
		else dAccess(false, {pid: tab.permission?.[0], pvar: tab.permission?.[1], internal: true});

		return (
			<Flex gap={2.5}>
				{(!this.props.useSelect ? this.renderTabs(tab, tabs) : this.renderSelect(tab, tabs))}
				{component}
			</Flex>
		);

	}


	/**
	 * Render our tabs.
	 *
	 * @param {Object|null} activeTab
	 * @param {Array<Object>} tabs Tab definitions
	 * @return {ReactNode}
	 */
	renderTabs(activeTab, tabs) {
		return (
			<Tabs
				classes={this.constructor.classes}
				indicatorColor="primary"
				onChange={this.handleChange}
				textColor="primary"
				TabScrollButtonProps={this.constructor.TabScrollButtonProps}
				style={this.style}
				value={this.constructor.resolveTabId(activeTab)}
				variant="scrollable">
				{
					tabs.map((tab, key) => (
						<Tab
							component={ReactLink}
							className={scss.Tab}
							key={key}
							label={tab.label}
							style={tab.styles}
							value={this.constructor.resolveTabId(tab)}
							to={tab.uri}
							onClick={e => {
								e.preventDefault();
								Navigator.navigate(tab.uri);
							}} />
					))
				}
				{(this.props.extraLinkUri && this.renderExtraLink())}
			</Tabs>
		);
	}


	/**
	 * Render as a selection dropdown.
	 * 
	 * @param {Object|null} activeTab
	 * @param {Array<Object>} tabs Tab definitions
	 * @return {ReactNode}
	 */
	renderSelect(activeTab, tabs) {

		const options = tabs.map(tab => {

			const id = this.constructor.resolveTabId(tab);

			return {
				...tab,
				id,
				value: id
			};

		});

		if (this.props.extraLinkUri) {
			options.push({
				id: this.constructor.extra_link_id,
				label: this.props.extraLinkLabel,
				value: this.constructor.extra_link_id
			});
		}

		if (!activeTab) {
			options.push({
				id: -1,
				label: "(Invalid URI)",
				value: -1,
				disabled: true
			});
		}

		return (
			<Select
				className={scss.Select}
				onChange={this.handleChangeSelect}
				options={options}
				removeLabelOption={true}
				size="small"
				style={this.style}
				value={this.constructor.resolveTabId(activeTab)} />
		);

	}


	/**
	 * Render the "extra" link aside from the tabs.
	 * 
	 * @return {ReactNode}
	 */
	renderExtraLink() {
		return (
			<Link
				className={scss.ExtraLink}
				label={this.props.extraLinkLabel}
				uri={this.props.extraLinkUri} />
		);
	}


	/**
	 * Render default content.
	 *
	 * @return {ReactNode|null}
	 */
	renderDefault = () => {
		if (this.props.renderDefault) {
			return this.props.renderDefault();
		}
		else return null;
	};


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			minHeight: (this.props.sticky ? "3rem" : undefined),
			marginBottom: (this.props.sticky ? "-2.5rem" : undefined),
			marginTop: (this.props.sticky ? "-0.5rem" : undefined),
			paddingBottom: (this.props.sticky ? "1rem" : undefined),
			paddingTop: (this.props.sticky ? "0.5rem" : undefined),
			position: (this.props.sticky ? "sticky" : undefined),
			top: (this.props.sticky || undefined),
			zIndex: (this.props.sticky ? 100 : undefined)
		};
	}


	/**
	 * Get the active tab object.
	 *
	 * @return {Object|undefined}
	 */
	get tab() {

		const {pathname, search} = this.props.location;
		const uri = `${pathname}${search}`;
		const qsRoute = qs.parse(search);

		/**
		 * Match our tab
		 */
		return this.props.tabs.find(tab => {

			/**
			 * Tab URIs can be defined as an array or single element
			 */
			const uris = (tab.uris || (tab.uri && [tab.uri]) || []);

			/**
			 * Iterate all our URIs
			 */
			uriLoop: for (const u of uris) {

				/**
				 * Not an exact match
				 */
				if (u !== uri) {

					/**
					 * Break the tab's URI
					 */
					const [path, query] = u.split("?");

					/**
					 * Pathame matches
					 */
					if (path === pathname) {

						/**
						 * Checking query string?
						 */
						if (query) {

							/**
							 * Parse the query string
							 */
							const qsTab = qs.parse(query);

							/**
							 * Query string keys
							 */
							const qsKeys = Object.keys(qsTab);

							/**
							 * All query params need to match values
							 */
							for (const param of qsKeys) {
								if (qsRoute[param] !== qsTab[param]) {
									continue uriLoop;
								}
							}

							return true;

						}
						else return true;

					}

				}
				else return true;

			}

			/**
			 * Using regex matches
			 */
			if (tab.uriMatches) {
				return !!tab.uriMatches.find(u => {
					return (new RegExp(u)).test(uri);
				});
			}

			/**
			 * Nothing matches
			 */
			return false;

		});

	}


	/**
	 * Get visible tabs to show.
	 * 
	 * @return {Array}
	 */
	get tabsVisible() {
		return this.props.tabs.filter(tab => {
			return (
				!tab.hidden &&
				(!tab.permission || this.props.hasPermission(...tab.permission))
			);
		});
	}


	/**
	 * Resolve a tab's ID.
	 *
	 * We use the tab label as a fallback when no ID's assigned.
	 *
	 * @param {Object} tab
	 * @return {String|null}
	 */
	static resolveTabId(tab) {
		return (tab?.id || tab?.label);
	}


	/**
	 * Sort tab definition objects.
	 *
	 * @param {Array<Object>} tabs
	 * @return {Array<Object>}
	 */
	static sortTabs(tabs) {
		return tabs.sort((a, b) => {
			if ((a.order !== undefined) && (b.order === undefined)) return -1;
			else if ((b.order !== undefined) && (a.order === undefined)) return 1;
			else if (a.order < b.order) return -1;
			else if (a.order > b.order) return 1;
			else return 0;
		});
	}


	/**
	 * Render 404 screen.
	 * 
	 * @return {ReactNode}
	 */
	static render404() {
		return (
			<Flex>
				<String
					str="Not Found"
					variant="h6" />
				<String
					color="textSecondary"
					str="Please check the URL you've visited." />
			</Flex>
		);
	}


	/**
	 * Classes.
	 * 
	 * @type {Object}
	 */
	static classes = {
		indicator: scss.Indicator,
		root: scss.Tabs
	};

	/**
	 * `TabScrollButtonProps`
	 * 
	 * @type {Object}
	 */
	static TabScrollButtonProps = {
		disabled: false
	};

	/**
	 * ID of our "extra link" when it's rendered as an option.
	 *
	 * @type {String}
	 */
	static extra_link_id = "extra";

}

export default withAuth(withRouter(TabPanel));
