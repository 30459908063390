import {useEffect} from "react";
import Container from "Components/Container.js";
import Divider from "@material-ui/core/Divider";
import Document from "Helpers/Document.js";
import LoginView from "Login/LoginView.js";
import Pre from "Components/Pre.js";
import String from "Components/String.js";
import Strings from "./MainDenied.strings.json";
import TelemetryService from "Telemetry/TelemetryService.js";
import UiDebug from "Ui/UiDebug.js";
import dAccess from "Dispatchers/dAccess.js";
import dOrg from "Dispatchers/dOrg.js";
import withAuth from "Hoc/withAuth.js";
import withMobile from "Hoc/withMobile.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * Access denied screen.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const MainDenied = props => {
	/**
	 * Try to switch the user to the target organisation when 
	 * access was denied because they're viewing a resource 
	 * at another railway.
	 * 
	 * @return {void}
	 */
	const switchToTargetOrg = () => {
		const targetOrgId = parseInt(props.AccessReason?.org);

		if (targetOrgId !== props.org?.Id) {
			if (props.orgIds?.includes(targetOrgId)) {
				dOrg(targetOrgId, (targetUri || "/"));
			}
		}
	};

	/**
	 * Render details.
	 * 
	 * @return {ReactNode}
	 */
	const renderDetails = () => (
		<Pre error={true}>
			<p><strong>P: </strong> {(props.AccessReason?.pid ?? "-")}</p>
			<p><strong>V: </strong> {(props.AccessReason?.pvar ?? "-")}</p>
			<p><strong>R: </strong> {(props.AccessReason?.org ?? "-")}</p>
			<p><strong>T: </strong> {(targetUri || "-")}</p>
			<p><strong>I: </strong> {(props?.AccessReason?.internal ? "Yes" : "-")}</p>
			<p><strong>X: </strong> {`${(props.AccessReason?.request || "-")} ${(props.AccessReason?.response ? `(${props.AccessReason?.response})` : "")}`.trim()}</p>
		</Pre>
	);

	/**
	 * Strings.
	 * 
	 * @return {Object}
	 */
	const strings = props.authed ? Strings.authed : (!props.AuthResetWasUnexpected ? Strings.auth : Strings.authUnexpected);

	/**
	 * Get the target URI that the user was trying to reach.
	 *
	 * @return {String}
	 */
	const targetUri = (props.AccessReason?.uri || `${props.location.pathname}${props.location.search}`);

	useEffect(() => {
		const route = props.route;
		let AccessReason = props.AccessReason;

		/**
		 * Resolve the actual access reason when needed
		 */
		if (!AccessReason && route) {
			AccessReason = {
				pid: route.permission?.[0],
				pvar: route.permission?.[1]
			};

			dAccess(false, AccessReason);
		}

		/**
		 * Carry out global operations now
		 */
		Document.setTitle((strings.titleDocument || strings.title));
		TelemetryService.report("MainDenied", {AccessReason});

		/**
		 * Ensure we're at the right org!
		 */
		switchToTargetOrg();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.AccessReason, props.route]);

	/**
	 * Render main UI.
	 * 
	 * @return {ReactNode}
	 */
	return (
		<Container>
			<String str={strings.title} variant="h5" />
			<String color="textSecondary" str={strings.help} />
			{(props.authed && renderDetails())}
			{(props.authed && <UiDebug />)}
			{(props.authed && <String str={strings.helpFault} />)}
			{((!props.authed && props.isMobile) && <Container mt={0.5}><Divider /><LoginView /></Container>)}
		</Container>
	);
};

export default connect(({Access, AccessReason, AuthResetWasUnexpected}) => ({Access, AccessReason, AuthResetWasUnexpected}))(withAuth(withMobile(withRouter(MainDenied))));
